.no-selection {
    position: absolute;
    top: 56%;
    left: 65%;
}

.guest-media {
    position: absolute;
    top: 22%;
    left: 57%;
    overflow: auto;
}

.main-media-flex {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-left: 20px;
    margin-top: 2rem;
}

.main-media-flex h5{
    font-weight: 600;
    margin: 10px;
}

.file-uploader {
    display: flex;
}


.file-uploader input{
    border: 1px solid rgb(196, 196, 196);
    border-radius: 5px;
    margin: 10px;
    padding-right: 100px;
}

.file-uploader button {
    margin-left: 60px;
    margin-right: 70px;
}

.file-uploader p{
    text-align: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 70px;
}

.file-uploader-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .file-uploader-btn {
    background-color: var(--application-base-theme-primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 10rem;
    padding: 7px;
    margin-left: 7px;
} */

/* .file-uploader-btn:hover {
    cursor: pointer;
    background-color: var(--application-base-theme-primary-color);
} */