.AdvancedSearch_ContentWrapper{
    margin-inline: 5px;
}

.AdvancedSearch_ContentWrapper button{
    width: -webkit-fill-available;
}


.geust_table_searchBar{
    margin-top: 10px;
    margin-bottom: 5px;
}

.deallocation-confirmation-container{
    background-color: #fff;
    max-width: 500px;
    margin: auto;
    margin-top: 70px;
    padding: 20px;
}

.confirmation-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.id-card-container{
    max-width: 500px;
    margin: auto;
    margin-top: 70px;
}

.id-card-form{
    background-color: #fff;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    padding: 30px;
}

.id-card-form label{
    margin-top: 10px;
}

.id-card-form input{
    border: 1px solid rgb(213, 213, 213);
    border-radius: 5px;
    margin-top: 20px;
}

.id-card-submit{
    background-color: #2C679D;
    color: #fff;
}