.head{
    margin:20px;
    color:#000000;
    text-transform: uppercase;
}


.search-container{
    display: flex;
}

  .search {
    margin:20px;
    width: 80%;
    position: relative;
    display: flex;
  }
  
  .searchTerm {
    width: 100%;
    border: 1px solid #000000;
    border-right: none;
    padding: 5px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #9DBFAF;
  }
  
  .searchTerm:focus{
    color: #00B4CC;
  }
  
  .searchButton {
    
    
    border: 1px solid #000000;
    border-left: none;
    background: #fff;
    text-align: center;
    color: #000000;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
  }

  .main{
    margin: 20px;
  }