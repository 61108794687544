.header {
  display: flex;
  justify-content: space-between;
}

.addButtons {
  display: flex;
  justify-content: center;
}

.addSubject {
  padding: 4px;
}

.main-div {
  width: 100%;
}

.main-div h2 {
  color: black;
}

.circular-progress {
  position: absolute;
  top: 50%;
  left: 50%;
}

.subject-class {
  border: 1px solid black;
  border-radius: 7px;
  margin: 15px;
  padding: 8px;
  box-shadow: 0px 0px 5px gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subject-class h5 {
  width: 552px;
  margin: 3px;
}

.subject-date-time {
  font-weight: 600;
  margin-top: 10px;
  margin-left: 5rem;
}

.sessions {
  width: 1221px;
  align-self: center;
}

.addSession {
  margin-left: 10px;
}

.session-button {
  background: transparent;
  border: none;
  padding: 7px;
  margin-right: 31px;
  margin-left: 2rem;
}

.edit-session {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  padding-right: 7px;
}

.edit-session p {
  margin-right: 10px;
  margin-top: 13px;
}

.edit-session:hover {
  cursor: pointer;
  color: rgb(10, 34, 242);
}

.delete-session:hover {
  cursor: pointer;
  color: red;
}

.delete-subject {
  margin-right: 12px;
}

.delete-subject:hover {
  cursor: pointer;
  color: red;
}

.edit-subject {
  background: transparent;
  border: none;
  margin-right: 30px;
}

.edit-subject:hover {
  cursor: pointer;
  color: rgb(10, 34, 242);
}

.add-subject-form {
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  max-width: 500px;
  margin: auto;
  margin-top: 150px;
}

.add-session-form {
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  max-width: 1200px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

#timing-label {
  margin-top: 40px;
}

.add-subject-form input {
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
  max-width: 400px;
  margin-top: 20px;
}

.add-subject-form h5 {
  font-weight: 600;
  margin-bottom: 10px;
}

#add-subject-button {
  background-color: rgb(66, 83, 151);
  color: #fff;
  margin-top: 40px;
  padding: 5px;
}

.add-session-form input {
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
  max-width: 400px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.add-session-form label {
  margin-top: 15px;
}

.add-session-form h5 {
  font-weight: 600;
}

.close-btn {
  display: flex;
  justify-content: flex-end;
}

#add-session-button {
  background-color: rgb(66, 83, 151);
  color: #fff;
  margin-top: 40px;
  padding: 5px;
}

.sessions_page_search_bar_wrapper {
  margin-inline: 1.2rem;
}
