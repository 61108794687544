.LoSemiDesc{
    padding: 0.5rem 1rem;
    background-color: rgba(128, 128, 128, 0.054);
    margin-bottom: 2%;
    border-radius: 2%;
    color: #3555A1A8;
}

.lo_bar-searchBar{
    display: flex;
}