.RoomTable{
    border: 1px solid;
    margin-top: 30px;
    margin-left: 0;
    border-radius: 0;
}

.guestTable{
    border: 1px solid;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 0;
    border-radius: 0;
}

.HotelTable{
    border: 1px solid;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 0;
    border-radius: 0;
}

.tableData{
    border: 1px solid;
}

.tableDataHead{
    border: 1px solid;
    font-weight: 600;
    text-align: center;
}

.tableData:hover{
    cursor: pointer;
}

.assignGuestButton{
    background-color:var(--application-base-theme-primary-color);
    color: #fff;
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
}

.inputSearchDiv{
    margin: 20px;
}

.inputSearch{
    width: 100%;
    border: 1px solid rgb(203, 200, 200);
    padding: 5px;
    border-radius: 5px;
}

.assignLOdisableRow{
    background-color: #580303;
}

.assignLoactiveRow{
    background-color: #f8f8f8;
}