.id-card-image-div{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    padding: 10px;
    width: 20%;
    height: 60%;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.id-card-image-close-button{
    border: none;
    background-color: #fff;
    float: right;
}

.id-card-image{
    border-radius: 8px;
    width: 100%;
    height: 95%;
}