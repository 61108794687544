.main-row:hover,
.main-row:hover #hotelLocation {
  background-color: #3e54ac52 !important;
  color: #222 !important;
  transition: 200ms ease;
}
.main-row:hover #hotelLocation {
  background-color: #3e54ac52 !important;
  color: #222 !important;
  text-decoration: underline;
}

.AddGuestbtn {
  margin: 0;
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  background: var(--application-base-theme-primary-color);
  font: inherit;
  color: #fefefe;
  cursor: pointer;
  outline: none;
}

.btn {
  color: white !important;
}

.App-link {
  color: #61dafb;
}

.prime_table_header_tab {
  background-color: var(--application-base-theme-primary-color);
  color: #f8f8f8;
}

.prime_table_header_tab svg {
  color: #f8f8f8;
}

.rounded-image {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.trashIcon {
  color: #222;
  margin: 5px;
  margin-inline: 10px;
  transition: 200ms;
}

.trashIcon:hover {
  color: red;
  transition: 200ms;
}

.page_header_title_h3 {
  color: var(--application-base-theme-black-color);
  margin-left: 7px;
}

/* Application Btn Styles */

.application_base_btn {
  color: var(--application-base-theme-secondary-color);
  background-color: var(--application-primary_btn_background-color);
  display: inline-block;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: bottom;
  border: 0;
  cursor: pointer;
  border-radius: 5px;
  transition: 300ms ease;
}

.btn_with_logo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.45rem;
}

.application_base_btn:hover {
  filter: brightness(1.1);
  box-shadow: 0 8px 9px -4px rgba(0, 0, 0, 0.15),
    0 4px 18px 0 rgba(0, 0, 0, 0.1);
  transition: 300ms ease;
}

.application_base_btn:disabled {
  filter: brightness(0.9);
  background-color: var(--application-disabled-background-color);
  color: var(--application-disabled-text-color);
  transition: 300ms ease;
}

.table_btn_padding {
  padding: 0.4rem 0.5rem;
}

.i-tagged-icons {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.max_media_width {
  width: 10rem;
  padding: 0.6rem 0.5rem;
}

.update_header_bars_btn {
  padding: 0.4rem 1.75rem;
  margin-inline: 0.5rem;
}

/* Application Btn Styles */

/* Application Header Btn Styles */

.header-btn {
  border: none;
  background-color: rgb(34, 34, 34);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin: 0 0.5rem;
  align-self: center;
}

.header-btn:disabled{
  border: none;
  background-color: var(--application-disabled-background-color);
  color: var(--application-disabled-text-color);
  border-radius: 5px;
  margin: 0 0.5rem;
  align-self: center;
}

.add-btn {
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  justify-content: space-between;
}

/* Main div */
.mainDiv {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  height: 100vh;
}

/* Search Bar */

.application_page_search_bar_wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

.application_search_bar_search_Icons:hover {
  filter: brightness(1.1);
  box-shadow: 0 8px 9px -4px rgba(0, 0, 0, 0.15),
    0 4px 18px 0 rgba(0, 0, 0, 0.1);
  transition: 300ms ease;
}

.application_page_search_bar {
  flex: 10;
  border: 2px solid black;
  color: black;
  border-radius: 5px;
  margin-inline: 10px;
  padding: 0.25rem 0.5rem;
}

.application_refresh_icon {
  flex: 1;
}

/* Add Modals */

.application_addition_input_fields {
}

/* Form Modal Input Fields */
.application_input_field_form_version {
  margin: 0;
  width: -webkit-fill-available;
  border: thin solid black;
  border-radius: 5px;
}

/*Semi Description Styles*/

.application_semi_description_bar_wrapper {
  box-shadow: 1px 1px 0px -0.5px #2c679d7a;
  border-radius: 5px;
  padding: 0.5rem;
  color: var(--application-base-theme-black-color);
}

.application_guest_search_bar_elements {
  flex: 2 1;
  border: solid thin var(--application-base-theme-black-color);
  color: black;
  border-radius: 5px;
  margin-inline: 10px;
  padding: 0.25rem 0.3rem;
  font-size: 14px;
}

/* Poster Sized Iamge */
.application_poster_size_image{
  height: 500px;
  width: 500px;
  object-fit:cover;
  aspect-ratio: 1/1;
}

.application_rkit_logo{
  height: 30px;
  width: auto;
  text-align: center;
}

.rkit_logo_div{
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 46vw;
}

/* ID doc image */
.doc_not_found{
  height: 400px;
  overflow-y: auto;
  display: flex;
  align-content: center;
  border: solid black;
 
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  color: var(--application-disabled-background-color);
}
.id_doc_image{
  max-height: 400px;
  overflow-y: auto;
  display: -webkit-box;
  align-content: center;
  border: solid black;
  margin: 20px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  
}

.id_doc_image img {
  max-width: 100%;
  max-height: 90%;
}
/* .no-image {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: red;
} */