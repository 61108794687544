.a {
	background-color: #00c0ef;
}

.card-box {
    position: relative;
	height:250px;
    color: #fff;
    padding: 20px 10px 40px;
    margin: 20px 0px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
	border-radius: 10px;
}
.card-box:hover {
    text-decoration: none;
    color: #f1f1f1;
}
.card-box:hover .icon i {
    font-size: 100px;
    transition: 1s;
    -webkit-transition: 1s;
}
.card-box .inner {
    padding: 5px 10px 0 10px;
}
.card-box h3 {
    font-size: 27px;
    font-weight: bold;
    margin: 0 0 8px 0;
    white-space: nowrap;
    padding: 0;
    text-align: left;
}
.card-box p {
    font-size: 15px;
}
.card-box .icon {
    position: absolute;
    top: auto;
    bottom: 5px;
    right: 5px;
    z-index: 0;
    font-size: 72px;
    color: rgba(0, 0, 0, 0.15);
}
.card-box .card-box-footer {
    position: absolute;
    left: 0px;
    bottom: 0px;
    text-align: center;
    padding: 3px 0;
    color: rgba(255, 255, 255, 0.8);
    background: rgba(0, 192, 239, 0.1);
    width: 100%;
    text-decoration: none;
}
.card-box:hover .card-box-footer {
    background: rgba(0, 0, 0, 0.3);
}

.bg-blue {
    background-color: #425397 !important;
}
.bg-green {
    background-color: #00a65a !important;
}
.bg-orange {
    background-color: #f39c12 !important;
}
.bg-red {
    background-color: #d9534f !important;
}

.descBtn{
    background-color: rgb(66, 83, 151);
    border: none;
    padding: 0.3rem 0.5rem;
    border-radius: 3px;
    color: #fff;
}

.descBtn:hover{
    background-color: rgb(34, 34, 34);
}

table.data_primary_table{
    
}

table.data_secondary_table td{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
}
