.vehicleTable{
    border: 1px solid;
    margin-top: 30px;
    margin-left: 0;
    border-radius: 0;
}

.guestTable{
    border: 1px solid;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 0;
    border-radius: 0;
}

.tableData{
    border: 1px solid;
}

.tableDataHead{
    border: 1px solid;
    font-weight: 600;
    text-align: center;
}

.tableData:hover{
    cursor: pointer;
}

.assignGuestButton{
    background-color: var(--application-base-theme-primary-color);
    color: #fff;
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    margin-inline: 10px;
}
.assignGuestButtonDisabled{
        background-color: #6c757d;
        cursor: not-allowed;
        opacity: 0.6;
    color: #fff;
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    margin-inline: 10px;
    }


.assignPairBtn{
    background-color: var(--application-base-theme-primary-color);
    color:  #fff;
    border: none;
    padding: 0.5rem;
}

.assignPairButton{
    background-color: var(--application-base-theme-primary-color);
    color: #fff;
    border: none;
    padding: 0.5rem;
    padding-inline: 2rem;
    border-radius: 5px;
    margin-inline: 10px;
}

.assignPairButtonDisabled{
    background-color: #6c757d;
    cursor: not-allowed;
    opacity: 0.6;
    color: #fff;
    border: none;
    padding: 0.5rem;
    padding-inline: 2rem;
    border-radius: 5px;
    margin-inline: 10px;
}

.inputSearchDiv{
    margin: 20px;
}

.inputSearch{
    width: 100%;
    border: 1px solid rgb(203, 200, 200);
    padding: 5px;
    border-radius: 5px;
}

.modal_header_Tooltip_Holder{
    width: 50vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    flex-wrap: nowrap;
}

.modal_body_pair_display_holder{
    display: flex;
    width: max-content;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 10px;
}

.modal_body_pair_display_wrapper_Div{
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 10px;
}