.Done_Btn {
  margin: 5px;
  border: none;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--application-base-theme-primary-color);
  color: #f8f8f8;
  width: -webkit-fill-available;
}

.Main_Button {
  margin: 3px;
  border: none;
  padding: 5px;
  border-radius: 5px;
  background-color: #32de84;
  color: #000000;
  font-weight: 600;
  width: -webkit-fill-available;
}

.rejected_button {
  background-color: #c0c0c0;
  color: #505050;
}

.completed_button {
  color: #696969;
}

.Reject_Btn {
  margin: 5px;
  width: 50%;
  border-width: thin;
  padding: 5px;
  font-weight: 600;
  background: transparent;
  width: -webkit-fill-available;
  color: red;
  border-radius: 5px;
}

.Requests_Card_Content_Wrapper {
  width: -webkit-fill-available;
  overflow-y: scroll;
  height: 75vh;
  padding-top: 2vh;
  padding-bottom: 5vh;
}
.Card_Wrapper {
  display: flex;
  flex-direction: row;
}
.Content_Wrapper {
  width: 100%;
  /* background-color: var(--application-base-theme-primary-color); */
  padding: 10px;
  border-radius: 8px;
  color: #000000;
  margin: 8px;
  display: flex;
  justify-content: space-between;
}
.Description_Sender_wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.Guest_wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.Description_Wrapper {
  margin-inline: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Header_Div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
}
.timestamp {
  font-size: 16px;
  font-weight: 500;
}

.description_content_wrapper {
  margin-top: 20px;
  text-align: justify;
  text-justify: inter-word;

  /* padding-inline-start: 10px; */
  font-size: 16px;
}

.Sender_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-width: 200px;
  background-color: var(--application-base-theme-primary-color);
  color: #ffffff;
  border-radius: 5px;
  padding-inline: 15px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  margin-bottom: 5.5px;
  margin-top: 5.5px;
}

.description_title {
  font-size: 22px;
  font-weight: 600;
}

.Card_Holder {
  border: 2px solid #ccc;
  border-radius: 7px;
  margin-bottom: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.box_name {
  margin-left: 10px;
  margin-top: 2px;
  padding-inline: 5px;
}

.chip_target {
  
  border: 1px solid #000000;
  border-radius: 7px;
  background-color: #ee82ee;
  margin: 5px;
  padding: 4px;
  font-weight: 500;
  min-width: 100px;
  font-size: 14px;
  cursor: default;
}
.chip_reject {
  border-radius: 7px;
  padding: 4px;
  text-decoration: underline;
  font-weight: 600;
  font-size: 14px;
  color: #ff0000;
  background-color: #ffffff;
  border: 1px solid #000000;
  margin: 5px;
  min-width: 200px;
}
