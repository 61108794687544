input, label{
    width: auto;
    display: inline !important;
    margin: 0 0.5rem;
}

.date-input{
    margin: 0.5rem 0;
}

.checkbox-div{
    margin: 0.7rem 0;
    font-size: 1.2rem;
}

.textarea{
    padding: 1rem !important;
    border-radius: 10px;
}

.div1{
    display: flex;
    justify-content: space-between;
}

.div1 > div{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.title-div{
    display: flex;
    justify-content: space-between;
}

.div2{
    margin: 1rem 0 0 0;
    display: flex;
    justify-content: space-between;
}
.venue-div{
    margin: 1rem 0;
}