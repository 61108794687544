.country-code-readOnly {
    border: 1px solid rgb(188, 188, 188);
    border-radius: 5px;
    height: 6vh;
    width: 2.8vw;
    margin-right: 3px;
}

.contactInput {
    border: 1px solid rgb(188, 188, 188);
    border-radius: 5px;
    height: 6vh;
    width: 50rem;
}

.Addbtn{
    margin-top: 50px;
    
}
.buttonPlace{
    display: flex;
    flex-direction: row-reverse;
}