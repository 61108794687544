.HotelsDesc{
    padding: 0.5rem 1rem;
    background-color: rgba(128, 128, 128, 0.054);
    border-radius: 2%;
    color: #3555A1A8;
}

.guest-name {
    margin-top: "30px";
}

.open_locked_rooms-btn{
    border: "none";
    border-radius: "5px";
    background-color: var(--application-base-theme-primary-color); 
    color: "#fff";
    padding: "7px"; 
    margin: 10px;
    transition: 400ms;
}
.open_locked_rooms-btn:disabled{
    cursor: not-allowed;
    opacity: 0.6;
    margin: 10px;
    background-color: var(--application-disabled-background-color);  
    color: var(--application-disabled-text-color);
}

.hotel_searchBar{
    margin-inline: 20px;
}

.hotel_desc_button_wrapper{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}