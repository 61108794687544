.main-div {
    width: 100%;
}

.searchBar{
    margin-bottom: 5px;
}

.search_contacts{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
}

.add-contact-form {
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 500px;
    margin: auto;
    margin-top: 150px;
    justify-self: center;
}

.searchTerm {
    border: 1px solid black;
    border-radius: 4px;
}

.add-contact-form input{
    border: 1px solid rgb(225, 225, 225);
    border-radius: 5px;
    /* max-width: 400px; */
    margin-top: 10px;
    width: 100%;
}
.add-contact-form label{
   
    margin-top: 5px;
}

