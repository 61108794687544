.right-sidebar {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 10vh;
    width: 30vw;
    background-color: #F5F5F5;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 999;
    transition: height 0.5s ease-in-out;
    overflow-y: scroll;
  }
  
  .right-sidebar.expanded {
    height: 100vh;
  }
  
  .right-sidebar__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #F5F5F5;
    color: #333333;
    font-weight: bold;
    cursor: pointer;
  }
  
  .right-sidebar__header:hover {
    background-color: #E0E0E0;
  }
  
  .right-sidebar__header-text {
    font-size: 1.2rem;
  }
  
  .right-sidebar__header-icon {
    font-size: 1.5rem;
    line-height: 1;
    margin-left: 1rem;
  }
  
  .right-sidebar__card {
    border: 1px solid #E0E0E0;
    background-color: #FFFFFF;
    padding: 1rem;
    margin: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .right-sidebar__card:hover {
    background-color: #F5F5F5;
  }
  
  .right-sidebar__card-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .right-sidebar__card-content {
    font-size: 1rem;
  }

  .Request_Done_Btn{
    border: none;

    padding: 10px;
    border-radius: 5px;
    background-color: #2C679D;
    color: #F8F8F8;
    padding-inline: 35px;
    width: -webkit-fill-available;
  }

  .Request_Reject_Btn{
    width: 50%;
    border-width: thin;
    padding: 10px;
    font-weight: 600;
    background: transparent;
    width: -webkit-fill-available;
    color: red;
    border-radius: 5px;
  }

.Requests_Card_Content_Wrapper{
  width: -webkit-fill-available;
  overflow-y: scroll;
  height: 75vh;
  padding-top: 2vh;
  padding-bottom: 5vh;
}

.Request_Content_Wrapper{
  background-color: #2C679D;
  padding: 5px;
  overflow: hidden;
  border-radius: 8px;
  color: whitesmoke;
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.Request_Description_Wrapper{
  margin-inline: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: nowrap;
}

.Request_Header_Div{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.Request_description_content_wrapper{
  max-width: 400px;
  text-align: start;
  padding-inline-start: 10px;
  font-size: 15px;
}

.Request_Sender_block{    
  background-color: #F8F8F8;
  color: #222;
  border-radius: 5px;
  padding-inline: 15px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  margin-bottom: 5.5px;
  margin-top: 5.5px;
}

.Request_description_title{
  font-size: 18px;
  font-weight: 600;
}

.Request_Card_Holder{
  overflow: hidden;
}

.Request_Description_Wrapper{

}