.Margin  {
    margin-left: 30%;
}

h4{
    color: var(--application-base-theme-primary-color);
}
.GuestSemiDesc-column div{
    color: #3555A1A8;
    white-space: nowrap;
}

.imgProfile {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.iconMagin {
    margin-right: 100px;
}

.ea_margin {
    margin-bottom: 25px;
}

.main-outerclass{
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    height: 100vh;
    overflow-y: scroll;
}

.GuestSemiDesc{
    font-size: 15px;
    padding: 2%;
    background-color: rgba(128, 128, 128, 0.054);
    margin-bottom: 2%;
    border-radius: 2%;
    height: 200px;
    overflow: auto;
    scroll-behavior: smooth;
}
.GuestSemiDesc-row {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    overflow: auto;
    height: 150px;
  }
  
  .GuestSemiDesc-column {
    padding: 1rem;
  }

.padder_divs{
    padding-top: 5px;
    padding-bottom: 5px;
}

.refreshBtn{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: var(--application-base-theme-primary-color);
    padding-inline: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
}


/* guest itinery css code */

.sec-div1{
    display: flex;
}

.main-div{
    padding: 1rem;
}

.guest-div{
    margin-right: 2rem;
}

.guest-details{
    display: flex;
}

.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fa-close{
    font-size: 2rem;
}

hr{
    border: 1px solid black !important;
}

.add-ticket{
    /* margin-left: 2rem; */
    height: fit-content;
}

.add-ticket > div, .add-ticket > h2{
    /* padding: 0 2rem; */
}

.time-div{
    margin-left: 2rem;
    display: flex;
    width: 30vw;
    justify-content: space-between;
}

.time-div > div > div{
    margin-bottom: 20px;
}

.time-div > div > div > h5{
    font-weight: bold;
}

.travel-info{
    /* width: fit-content; */
    border: 2px solid black;
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem 0;
    overflow-y: auto;
    justify-content: space-between;
}

.sec-div2{
    display: flex;
    padding: 1rem 0;
    margin: 1rem 0;
}

.img{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: var(--application-base-theme-primary-color);
    margin-right: 1rem;
    object-fit: cover;
}

.details-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.add-ticket-div1, .add-ticket-div2, .terminal-div{
    display: flex;
    justify-content: space-between;
}

label{
    display: block !important;
}

.label{
    padding: 0;
}

.add-ticket-div2, .terminal-div{
    margin-top: 1rem;
}

.add-ticket{
    margin-right: 2rem;
    padding: 2rem;
    border: thin solid black;
    border-radius: 15px;
}

.add-ticket-div1 > div, .add-ticket-div2 > div, .terminal-div > div{
    width: 50%;
    margin: 0 1rem 0 0;
}

.select-file-input{
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}


.add-ticket-btn{
    background-color: var(--application-base-theme-primary-color) !important;
    color: var(--application-base-theme-secondary-color) !important;
    width: 100%;
    border: none;
}

.download-ticket{
    background-color: var(--application-base-theme-primary-color) !important;
    color: var(--application-base-theme-secondary-color) !important;
    border: none;
}

input{
    width: 100%;
    border-radius: 12px;
    padding: 0.5rem;
    border: 2px solid black;
}

/* select{
    border-radius: 12px !important;
    padding: 0.5rem !important;
    border: 2px solid black !important;
} */

option{
    border-radius: 12px !important;
}

.travel-ticket-info{
    padding: 2rem 3.5rem;
    border: thin solid black;
    border-radius: 10px;
}

.travel-ticket-info > h2, .add-ticket > h2{
    margin-bottom: 2rem;
}

.btn-div{
    margin: 0 1rem 0 0;
}

.guest_progfile_img{
    width: auto;
    height: 120px;
    clip-path: circle();
}

.guest_description_main_div{
    display: flex;
    flex-direction: row; 
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.uploadBtn{
    border: none;
    color: var(--application-base-theme-primary-color);
    font-size: 1.5rem;
    border-radius: 100%;
    margin: 0 0.5rem;
    border: 1px solid black;
    font-size: 1.2rem;
}

.upload-div{
    display: flex;
    align-items: center;
}

.itenary-card-div{
    display: flex;
    margin-top: 1rem;
}
.itenary-card-div>div{
    width: 50%;
    margin: 0 1rem 0 0;
}

.tickets-card{
    height: calc(90%);
    overflow: auto;
}

.loader-div{
    display: flex;
    justify-content: center;
    align-self: center;
}

.add-loader-div{
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: 1rem;
}

.loader{
    padding: 0.3rem;
}

.btn-div{
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 20px;
}