.main-bg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    padding-top: 48px;
}

.wrapper{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input{
    width: 100%;
}

.moreOptions{
    display: flex;
    justify-content: space-between;
}

.loginBtn{
    width: 40%;
    padding: 0.3rem 0.65rem 0.3rem 0.65rem;
}

.form-div{
    width: 100%;
}

.loginBtn-div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}

.logo-div{
    width: 70%;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo{
    width: 80%;
    height: auto;
    margin: auto;
}

.error-div{
    margin: 0 !important;
    padding: 0.4rem 0;
}
