:root{
  --application-base-theme-primary-color : #2C679D;
  --application-base-theme-secondary-color : #F8F8F8;
  --application-base-theme-black-color : #222222;

  --application-base-theme-guest-card-color : #2C679D;
  --application-base-theme-guest-graph-color : #e5f1ff;

  --application-base-theme-guest-graph-text-color : #484848;

  --application-base-theme-table-even-rows-color : #F0F0F0;
  --application-base-theme-table-odd-rows-color : #E0E0E0;
  --application-base-theme-table-row-hover-color : #FFFFFF; /* D28 */

  /* Pastel Color Background For Graph Holders */
  --application-pastel-color-blue : #E5F1FF;
  --application-pastel-color-red : #FFE5E5;
  --application-pastel-color-purple : #f8e5ff96;
  --application-pastel-color-green : #E5FFE7;

  /* Card Panel Background Color for Data Holders */
  --application-card-data-holder-blue : #3F89BE;
  --application-card-data-holder-red : #CB8F8F;
  --application-card-data-holder-purple : #C076AC;
  --application-card-data-holder-green : #7AC1AC;
  

  /* Graph Colors */
  --application-pastel-graph-blue : #b3cbe2;
  --application-pastel-graph-red : #fbb3ab;
  --application-pastel-graph-orange: #fbdba4;
  --application-pastel-graph-green: #cbeac4;
  --application-pastel-graph-violet: #dccbe3;
  --application-pastel-graph-yellow: #ffffcc;
  --application-pastel-graph-white: #ededf0;

  /* Button Colors*/
  --application-primary_btn_background-color : #2C679D;

  /* Button disabled Colors */
  --application-disabled-background-color : #6c757d;
  --application-disabled-text-color : #fff;

  /* Request Card Colors */
  --application-request-tab-color-purple-Vehicle_Admin : #d99bff;
  --application-request-tab-color-pastel_Green-Guest_Admin : #a3e7da;
  --application-request-tab-color-yellow-LO_Admin : #e8ea98;
  --application-request-tab-color-pink-Requests_Admin : #f296c2;
  --application-request-tab-color-green-Session_Admin : #82f5be;
  --application-request-tab-color-yellow-Hotel_Admin : #edd0a3;
  --application-request-tab-color-purple-Emergency: #ff7764;
  --application-request-tab-color-purple-Media: #9da7ff;

  /* Error text Color */

  --application-error-text-color-form: #FF3333;
}

html{
  font-family: 'Poppins', sans-serif !important ;
  -webkit-font-smoothing: antialiased !important ;
  -moz-osx-font-smoothing: grayscale !important ;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Poppins', sans-serif !important ;
} 

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--application-base-theme-primary-color) var(--application-base-theme-secondary-color);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
 
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-track {
    background: var(--application-base-theme-secondary-color);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--application-base-theme-primary-color);
    border-radius: 20px;
    border: 1px none var(--application-base-theme-secondary-color);
  }

  .mui_grid_table_even_row{
    background-color: var(--application-base-theme-table-even-rows-color);
  }
  .mui_grid_table_odd_row{
    background-color: var(--application-base-theme-table-odd-rows-color);
  }

  .mui_grid_table_even_row .mui_grid_table_odd_row :hover{
    background-color: var(--application-base-theme-table-row-hover-color);
  }
