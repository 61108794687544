.MDB_Modal_Header_Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
}

.MDB_Modal_Header_Right_Box_Wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.btn-close{
    margin: 0;
}