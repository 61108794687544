.pair_display_content_holder{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;   
}

.pair_item_content_holder{
    border: dashed;
    border-width: thin;
    padding: 5px;
    border-radius: 12px;
}

.pair_display_holder{
    margin-inline: 8px;
}
.color_pallet_pair_0{
    background-color: #54c85b56;
}
.color_pallet_pair_1{
    background-color: #82e9e46f;
}

.color_pallet_pair_2{
    background-color: #ecff008a;
}
.color_pallet_pair_3{
    background-color: #4892568c;
}
.color_pallet_pair_4{
    background-color: #68b9848b;
}
.color_pallet_pair_5{
    background-color: #f1a19a7d;
}
.color_pallet_pair_6{
    background-color: #f7c9e18e;
}
.color_pallet_pair_7{
    background-color: #e2d2cdc2;
}
.color_pallet_pair_8{
    background-color: #c390d98f;
}
.color_pallet_pair_9{
    background-color: #ccd5ae88;
}
.color_pallet_pair_10{
    background-color: #96958e8a;
}